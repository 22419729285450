export default (pageObjectKey, page, version) => {
  const pageSelector = page === 'menu' ? 'Menu' : 'Page'

  switch (pageObjectKey) {
    case 'blogpage':
      return `blog${pageSelector}`

    case 'teampage':
      return `team${pageSelector}`

    case 'presspage':
      return `press${pageSelector}`

    case 'generalpage':
      return `general${pageSelector}`

    case 'policiespage':
      return `policies${pageSelector}`

    default:
      break
  }
}

// there are five types of data in the grahpql you can choose from. based on page object key
// you are the selector will give the key to the right variant to the right stylesheet

// the variant names

// generalMenu
// generalPage
// policiesMenu
// policiesPage
// pressMenu
// pressPage
// teamMenu
// teamPage
// etc
