import React from 'react'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { Box, Heading, Image, Text } from 'theme-ui'
import moment from 'moment'
import shortid from 'shortid'

export default function OtherArticles({ title, allArticlesData, currentArticleId, variant, pageObjectKey }) {
  const filteredArray = allArticlesData.filter(article => article.id !== currentArticleId)
  return (
    <Box variant={`${variant}.otherArticlesContainer`} className="otherArticlesContainer">
      <Heading as="h3" variant={`${variant}.otherArticleHeading`} className="otherArticleHeading">
        {title ? title : 'Other Articles'}
      </Heading>
      <Box variant={`${variant}.otherArticles`} className="otherArticles">
        {filteredArray.map(article => (
          <Link
            key={shortid.generate()}
            to={`/${pageObjectKey}/${slugify(article.title ? article.title : '', {
              lower: true
            })}`}
          >
            <Box variant={`${variant}.otherArticle`} className="otherArticle">
              {article?.media?.[0]?.cloudinaryId && (
                <Image
                  variant={`${variant}.otherArticleImage`}
                  className="otherArticleImage"
                  src={`https://res.cloudinary.com/gonation/${article?.media?.[0]?.cloudinaryId}`}
                />
              )}
              <Box variant={`${variant}.otherArticleContent`} className="otherArticleContent">
                <Heading as="h5" variant={`${variant}.otherArticleTitle`} className="otherArticleTitle">
                  {article.title}
                </Heading>
                <Heading as="h6" variant={`${variant}.otherArticleSubtitle`} className="otherArticleSubtitle">
                  {article.subtitle}
                </Heading>
                <Text as="p" variant={`${variant}.otherArticleDate`} className="otherArticleDate">
                  {moment(article.createdAt).format('DD MMM, YYYY')}
                </Text>
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  )
}
